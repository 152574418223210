import { type AdSizes } from '@/types/AdSizes';
import { type AdTargeting } from '@/types/AdTargeting';

export default function parseAdPackageConfig({
  adSizes = '{}',
  adTargeting = '{}',
}: {
  adSizes?: string | null;
  adTargeting?: string | null;
}): { sizes: AdSizes; targeting: AdTargeting } {
  let targeting: AdTargeting = {};
  let sizes = {};
  if (adTargeting) {
    try {
      targeting = JSON.parse(adTargeting);
      if (typeof targeting.pos === 'string') {
        targeting.pos = targeting.pos
          .split(',')
          .map((item: string) => item.trim());
      }
    } catch (e) {
      // noop
    }
  }

  if (adSizes) {
    try {
      sizes = JSON.parse(adSizes);
    } catch (e) {
      // noop
    }
  }

  // TODO: remove this once curation data has been re-migrated
  if (Array.isArray(sizes)) {
    // Handling old curated ad module data format
    sizes = sizes.reduce(
      (acum, item) => {
        // get breakpoint and sizes from data
        const [device, size] = item.id.split(':');
        const sizes = size.split('x').map((s: string) => +s);

        // add the current size to the subobject
        acum[device].push(sizes);

        // use same values for laptop ad dekstop.
        // we might want to remove laptop from adjs in the future.
        if (device === 'desktop') {
          acum.laptop.push(sizes);
        }

        return acum;
      },
      { desktop: [], laptop: [], mobile: [], tablet: [] },
    );
  }

  return {
    sizes,
    targeting,
  };
}
