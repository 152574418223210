'use client';

import { useState, type FunctionComponent } from 'react';
import {
  defaultTrendingConfig,
  defaultTrendingItemCount,
  trendingWidgetConfig,
} from '@/constants/trending';
import useStore from '@/store';
import { TrendingWidgetStrings as strings } from '@/translations';
import {
  Tabs,
  TabsContent,
  TabsItem,
  TabsList,
  Typography,
} from '@motortrend/ids';
import cx from 'clsx';
import { withErrorBoundary } from 'react-error-boundary';

import { type TrendingType } from '@/lib/gql/graphql';
import useTrendingItems from '@/hooks/useTrendingItems';
import { NoUiErrorFallback } from '@/components/ErrorFallback';
import TrendingItemList from '@/components/TrendingWidget/TrendingItemList';
import { type TrendingWidgetProps } from '@/components/TrendingWidget/TrendingWidget.props';

import { brandSelector } from '@/store/selectors/brand';

const TrendingWidget: FunctionComponent<TrendingWidgetProps> = ({
  count = defaultTrendingItemCount,
}) => {
  const { brand } = useStore(brandSelector);

  const { types: trendingTypes } =
    trendingWidgetConfig[brand] || defaultTrendingConfig;
  const { tabs } = trendingWidgetConfig[brand] || defaultTrendingConfig;
  const showTabs = !!(tabs && tabs?.length > 0);

  const [currentTab, setCurrentTab] = useState<TrendingType>(trendingTypes[0]);

  const { isFetching, trendingItems } = useTrendingItems({
    brand,
    size: count,
    trendingType: currentTab,
  });

  const handleTabChange = (value: string) => {
    setCurrentTab(value as TrendingType);
  };

  return (
    <section className="rounded-lg border border-neutral-6 p-3 shadow-[0px_4px_20px_0px_rgba(0,0,0,0.06)] dark:border-transparent dark:bg-neutral-3 dark:shadow-none">
      <Typography
        as="h2"
        className={cx({ 'mb-4': !showTabs })}
        data-testid="heading"
        lg="h4"
        variant="h5"
      >
        {strings.Heading}
      </Typography>

      {showTabs ? (
        <Tabs defaultValue={currentTab} onValueChange={handleTabChange}>
          <TabsList>
            {tabs.map(({ label, type }) => (
              <TabsItem key={type} value={type}>
                {label}
              </TabsItem>
            ))}
          </TabsList>
          <TabsContent value={currentTab}>
            <TrendingItemList
              count={count}
              isFetching={isFetching}
              trendingItems={trendingItems}
            />
          </TabsContent>
        </Tabs>
      ) : (
        <TrendingItemList
          count={count}
          isFetching={isFetching}
          trendingItems={trendingItems}
        />
      )}
    </section>
  );
};

export default withErrorBoundary(TrendingWidget, {
  FallbackComponent: NoUiErrorFallback,
});
