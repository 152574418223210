'use client';

import { type FunctionComponent } from 'react';

import { type AdSize } from '@/types/AdSizes';

import ConfigurableSlot from '@/utils/ads/googlePublisherTag/ConfigurableAdSlot';

import { type NativoAdProps } from './NativoAd.props';

export const NATIVO_AD_SIZES: Record<string, AdSize[]> = {
  DesktopNativo: [[1, 3], 'fluid'],
  MobileNativo: [[1, 3], 'fluid'],
  Nativo: [[1, 3]],
};

export const NATIVO_AD_SIZE_MAPPING: Record<
  string,
  googletag.SizeMappingArray
> = {
  DesktopNativo: [
    [
      [1024, 0],
      [[1, 3], 'fluid'],
    ], // Desktop and Laptop
    [[0, 0], []], // Mobile and Tablet (no ads)
  ],
  MobileNativo: [
    [[1024, 0], []], // Desktop and Laptop (no ads)
    [
      [0, 0],
      [[1, 3], 'fluid'],
    ], // Mobile and Tablet
  ],
  Nativo: [
    [[1024, 0], [[1, 3]]], // Desktop and Laptop
    [[0, 0], [[1, 3]]], // Mobile and Tablet
  ],
};

const nativoArticleId = '1111035';
const defaultCuratedNativoId = '1115836';

const getTargeting = ({
  adId,
  listing,
  pageTag,
}: NativoAdProps & { brand: string }): { [key: string]: string | string[] } => {
  switch (pageTag) {
    case 'ListArticle':
      return {
        native: 'midarticle',
        ntvPlacement: nativoArticleId,
      };
    case 'Curated':
      return {
        native: listing ? ['listings', listing.toString()] : 'listings',
        ntvPlacement: adId || defaultCuratedNativoId,
      };
    case 'Article':
      return {
        fluid: 'handshake',
        native: 'midarticle',
        ntvPlacement: nativoArticleId,
      };
    default:
      return {};
  }
};

const NativoAd: FunctionComponent<NativoAdProps> = ({
  adId,
  className,
  listing,
  pageTag,
  pageTargeting,
  size,
  sizeMap,
}) => {
  const { adTargeting } = pageTargeting;
  const { brand = 'motortrend' } = adTargeting;

  const nativoTargeting = getTargeting({
    adId,
    brand,
    listing,
    pageTag,
    pageTargeting,
  });
  const nativoSizes = pageTag === 'Article' ? size : NATIVO_AD_SIZES.Nativo;
  const nativoSizeMap =
    pageTag === 'Article' ? sizeMap : NATIVO_AD_SIZE_MAPPING.Nativo;

  const { ntvPlacement } = nativoTargeting;

  return (
    <div className={className}>
      <div className={`nativo-news nativo-news-${ntvPlacement}`}>
        <ConfigurableSlot
          pageTargeting={pageTargeting}
          size={nativoSizes as AdSize[]}
          sizeMapping={nativoSizeMap as googletag.SizeMappingArray}
          targeting={nativoTargeting}
        />
      </div>
    </div>
  );
};

export default NativoAd;
