'use client';

import { useState, type FunctionComponent, type ReactNode } from 'react';
import { RiverStrings as strings } from '@/translations';
import { Card, CardContent, CardGrid, CardTitle } from '@motortrend/ids';

import { DataId } from '@/utils/nitrous/constants';

const RiverCards: FunctionComponent<{
  showMoreButton: boolean;
  cards: ReactNode[];
}> = ({ cards = [], showMoreButton = false }) => {
  const increments = 5;

  const [showCards, setShowCards] = useState(
    showMoreButton ? increments : cards?.length || increments,
  );

  const showMore = showMoreButton && showCards < cards.length;

  return (
    <CardGrid spacing={1.5}>
      {cards.slice(0, showCards)}
      {showMore && (
        <Card
          as="button"
          data-id={DataId.RiverCard}
          data-parent={DataId.River}
          key={showCards + 1}
          onClick={() => {
            setShowCards(showCards + increments);
          }}
          orientation="horizontal"
          variant="tile"
        >
          <CardContent>
            <CardTitle
              title={strings.Loadmore}
              titleTypographyProps={{ as: 'p' }}
            />
          </CardContent>
        </Card>
      )}
    </CardGrid>
  );
};
export default RiverCards;
