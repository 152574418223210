import { type FunctionComponent } from 'react';
import { mdiPlayCircle } from '@mdi/js';
import Icon from '@mdi/react';
import { Card, CardContent, CardMedia, CardTitle } from '@motortrend/ids';

import { DataId } from '@/utils/nitrous/constants';
import AkamaiImage from '@/components/AkamaiImage';
import { NextLink } from '@/components/NextLink';
import { type TrendingItemListProps } from '@/components/TrendingWidget/TrendingItemList/TrendingItemList.props';
import TrendingItemSkeletons from '@/components/TrendingWidget/TrendingItemSkeletons';

const TrendingItemList: FunctionComponent<TrendingItemListProps> = ({
  count,
  isFetching,
  trendingItems,
}) => (
  <div className="mx-1 mb-1 mt-6 flex flex-col gap-4 divide-y divide-neutral-6 dark:divide-neutral-4 [&>*:first-child]:pt-0 [&>*]:pt-4">
    {isFetching ? (
      <TrendingItemSkeletons count={count} />
    ) : (
      trendingItems.map((trendingItem, index) => (
        <Card
          as={NextLink}
          data-id={DataId.TrendingArticlesLink}
          data-parent={DataId.TrendingWidget}
          href={trendingItem.link}
          key={index}
          orientation="horizontal"
        >
          <CardMedia
            as={AkamaiImage}
            aspectRatio="1/1"
            src={trendingItem.thumbnailUrl}
          >
            {trendingItem.type === 'video' && (
              <div className="absolute inset-0 flex items-center justify-center opacity-70 transition-opacity group-hover/card:opacity-100">
                <Icon className="size-8 text-neutral-8" path={mdiPlayCircle} />
              </div>
            )}
          </CardMedia>
          <CardContent>
            <CardTitle
              title={trendingItem.title}
              titleTypographyProps={{
                maxLines: 3,
                sm: 'body3',
                variant: 'body3',
              }}
            />
          </CardContent>
        </Card>
      ))
    )}
  </div>
);

export default TrendingItemList;
