'use client';

import { type FunctionComponent } from 'react';
import { withErrorBoundary } from 'react-error-boundary';

import { graphql } from '@/lib/gql';
import generateAdModuleClassname from '@/utils/ads/injectAdModuleClassWithSizes';
import parseAdPackageConfig from '@/utils/ads/parseAdPackageConfig';
import AdContainer from '@/components/AdContainer/AdContainer';
import { NoUiErrorFallback } from '@/components/ErrorFallback';

import { type AdModuleProps } from './AdModule.props';

export const AdModuleFragment = graphql(/* GraphQL */ `
  fragment AdModule on CurationModules {
    options {
      adSizes
      adTargeting
    }
  }
`);

const AdModule: FunctionComponent<AdModuleProps> = ({
  options,
  pageTargeting,
}) => {
  const { adSizes, adTargeting } = options || {};

  const { sizes, targeting } = parseAdPackageConfig({
    adSizes,
    adTargeting,
  });

  const finalTargeting = {
    ...targeting,
  };

  return (
    <AdContainer
      adContainerProps={{ className: generateAdModuleClassname(sizes) }}
      adProps={{ pageTargeting, sizes, targeting: finalTargeting }}
    />
  );
};

export default withErrorBoundary(AdModule, {
  FallbackComponent: NoUiErrorFallback,
});
