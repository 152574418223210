import { TrendingWidgetStrings as strings } from '@/translations';

import { TrendingWidgetConfig } from '@/types/Trending';

import { Brand, TrendingBrand, TrendingType } from '@/lib/gql/graphql';

export const defaultTrendingItemCount = 5;
export const trendingItemThumbnailSize = 83;
export const trendingItemHeight = 20;

export const trendingWidgetConfig: Partial<
  Record<Brand, TrendingWidgetConfig>
> = {
  [Brand.Motortrend]: {
    types: [
      TrendingType.All,
      TrendingType.Article,
      TrendingType.Vehicle,
      TrendingType.Video,
    ],
    tabs: [
      { type: TrendingType.All, label: strings.AllTab },
      { type: TrendingType.Article, label: strings.ArticlesTab },
      { type: TrendingType.Vehicle, label: strings.CarsTab },
      { type: TrendingType.Video, label: strings.VideosTab },
    ],
  },
  [Brand.Hotrod]: {
    types: [TrendingType.Article],
    brands: [TrendingBrand.HotRod],
  },
};

export const defaultTrendingConfig = trendingWidgetConfig[Brand.Motortrend]!;
