import { type AdSizes } from '@/types/AdSizes';

const adsBreakpointToTailwind: Record<string, string> = {
  desktop: 'xl', // desktop is xl and above
  laptop: 'lg',
  mobile: '', // mobile is default + sm
  tablet: 'md',
};

const generateAdModuleClassname = (sizes: AdSizes) => {
  let className = 'min-h-[250px] min-w-[300px]';

  Object.entries(sizes).forEach(([breakpoint, sizeArray]) => {
    const tailwindClass = adsBreakpointToTailwind[breakpoint];
    const visibilityClass = sizeArray.length ? 'block' : 'hidden';
    className += ` ${tailwindClass}${tailwindClass ? ':' : ''}${visibilityClass}`;
  });
  return className;
};

export default generateAdModuleClassname;
