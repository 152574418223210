import { type FunctionComponent } from 'react';
import {
  trendingItemHeight,
  trendingItemThumbnailSize,
} from '@/constants/trending';
import { Card, CardContent, Skeleton } from '@motortrend/ids';

import { type TrendingItemSkeletonsProps } from './TrendingItemSkeletons.props';

const TrendingItemSkeletons: FunctionComponent<TrendingItemSkeletonsProps> = ({
  count,
}) => {
  return Array.from({ length: count }).map((_, index) => (
    <Card key={index}>
      <Skeleton
        height={trendingItemThumbnailSize}
        width={trendingItemThumbnailSize}
      />
      <CardContent className="flex flex-col gap-1">
        <Skeleton height={trendingItemHeight} />
        <Skeleton height={trendingItemHeight} />
        <Skeleton height={trendingItemHeight} />
      </CardContent>
    </Card>
  ));
};

export default TrendingItemSkeletons;
